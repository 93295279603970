import logo from './logo.png';
import './nautile-app.css';
// import NautileForm from './NautileForm';
import NautileBanner from './NautileBanner';

function App() {
  return (
    <div className="nautile-app">
      <div className="form-wrapper">
        <div className="header"><img src={logo} className="logo" alt="logo" /></div>
        {/* <NautileForm/>    */}
        <NautileBanner/>
      </div>
    </div>
  );
}

export default App;
