
import { useState, useRef } from 'react';


const NautileBanner = () => {


    return (
        <div className="form-body">

            <div className='title'>Clôture des inscriptions</div>
            <p>
                Nous tenons à exprimer notre gratitude la plus sincère à tous ceux qui se sont inscrits à notre vidéo commémorative pour les 16 ans de Nautile. 
                <br/>
                Votre enthousiasme et votre soutien ont été extraordinaires. 
                <br/><strong>Les inscriptions sont désormais closes</strong>, et nous allons entamer le processus de sélection des
                participants en raison du nombre limité de places disponibles. 
                <br/>
                Restez à l'écoute pour plus d'informations, et   encore <strong>merci pour votre engagement à nos côtés.</strong> 
            </p>
            <div>
                <a href="https://www.nautile.nc/actu/2024/02/nautile-fete-ses-16-ans/">
                    <button>
                        plus d'infos
                    </button>
                </a>
            </div>



                    
        </div>
    )
}

export default NautileBanner;